import {
  ADD_AFFILIATE_TO_USER,
  ADD_AFFILIATE_TO_USER_ERROR,
  ADD_AFFILIATES_TO_USERS,
  ADD_AFFILIATES_TO_USERS_ERROR,
  APPROVE_AUTHORIZATION,
  APPROVE_AUTHORIZATION_ERROR,
  CLOSE_MODAL,
  DECODE_TOKEN,
  DELETE_CHANNEL_LOG_ITEM,
  DELETE_INTERAFFILIATE_CHANNEL_LOG,
  DELETE_INTERAFFILIATE_CHANNEL_LOG_ERROR,
  EXT_USER_MGT_URL,
  FILE_UPLOAD,
  FILE_UPLOAD_ERROR,
  FX_HISTORY,
  FX_HISTORY_ERROR,
  FX_HISTORY_UPDATE,
  FX_HISTORY_UPDATE_ERROR,
  GET_CHANNEL_LOGS,
  GET_CURRENCY,
  GET_LOGGED_IN_USER,
  GET_LOGGED_IN_USER_ERROR,
  GET_LOGS,
  GET_LOGS_ERROR,
  GET_OPERATORS,
  GET_PENDING_AUTHORIZATIONS,
  GET_SCHEME_STANDINGS,
  GET_SCHEME_STANDINGS_ERROR,
  GET_USER_AFFILIATES,
  GET_LOGGED_IN_USER_AFFILIATES,
  GET_USER_AFFILIATES_ERROR,
  GET_USERS,
  GET_USERS_ERROR,
  GETTING_CURRENT_USER_AFFILIATES,
  MANUAL_RESOLUTION,
  OPEN_MODAL,
  REFRESH,
  REMOVE_AFFILIATE_FROM_USER,
  REMOVE_AFFILIATE_FROM_USER_ERROR,
  UPLOADING,
  USER_MGT_URL_FRONTEND_URL,
  USER_MGT_URL_FRONTEND_URL_ERROR,
  GET_ALL_CURRENCY_CODES_ERROR,
  GET_ALL_CURRENCY_CODES,
  ADD_RATES,
  ADD_RATES_ERROR,
  OPEN_ALERT,
  CLOSE_ALERT,
  AUTHORIZATION_CHECK,
  GET_AUTHORIZATION_TOKEN,
  GET_AUTHORIZATION_TOKEN_ERROR,
  DELETE_FX_HISTORY,
  DELETE_FX_HISTORY_ERROR,
  GET_ADMIN_REPORTS,
  GET_ADMIN_REPORTS_ERROR,
  GET_POST_TRANSACTION_REPORT,
  GET_POST_TRANSACTION_REPORT_ERROR,
  GET_SETTLEMENT_USERS,
  GET_SETTLEMENT_USERS_ERROR,
} from "../actions/types";

export default function (state = {}, action) {
  switch (action.type) {
    case GET_AUTHORIZATION_TOKEN:
      return {
        ...state,
        refreshedAuthorizationToken: action.payload,
        refreshedAuthorizationTokenError: false,
      };
    case GET_AUTHORIZATION_TOKEN_ERROR:
      return {
        ...state,
        refreshedAuthorizationTokenError: action.payload,
        refreshedAuthorizationToken: false,
      };
    case AUTHORIZATION_CHECK:
      return { ...state, isUnAuthorized: action.payload };
    case OPEN_ALERT:
      return { ...state, alertError: action.payload.data };
    case CLOSE_ALERT:
      return { ...state, alertError: action.payload.data };
    case OPEN_MODAL:
      return { ...state, openModal: action.payload.data };
    case CLOSE_MODAL:
      return { ...state, openModal: action.payload.data };
    case EXT_USER_MGT_URL:
      return { ...state, ext_user_mgt_url: action.payload.data };
    case USER_MGT_URL_FRONTEND_URL:
      return {
        ...state,
        user_mgt_url: action.payload.data,
        user_mgt_url_Error: false,
      };
    case USER_MGT_URL_FRONTEND_URL_ERROR:
      return {
        ...state,
        user_mgt_url_Error: action.payload,
        user_mgt_url: false,
      };
    case DELETE_CHANNEL_LOG_ITEM: // actually changed now the File
      return {
        ...state,
        fileLogs: {
          ...state.fileLogs,
          data: {
            ...state.fileLogs.data,
            content: state.fileLogs.data.content.filter(
              (log) => log.id !== action.payload.data
            ),
          },
        },
      };
    case REFRESH:
      return { ...state, uploaded: action.payload };
    case UPLOADING:
      return { ...state, uploadProgress: action.payload };
    case FILE_UPLOAD:
      return {
        ...state,
        fileUpload: action.payload,
        uploadStatus: false,
        uploaded: true,
        isError: false,
      };
    case FILE_UPLOAD_ERROR:
      return {
        ...state,
        fileUpload: action.payload,
        uploadStatus: false,
        uploaded: true,
        isError: true,
      };
    case MANUAL_RESOLUTION:
      return {
        ...state,
        manualResolution: action.payload.data,
        selectedItem: action.payload.selectedItem,
      };
    case FX_HISTORY:
      return {
        ...state,
        exchangeRates: action.payload,
        exchangeRatesError: false,
      };
    case FX_HISTORY_ERROR:
      return {
        ...state,
        exchangeRatesError: action.payload,
        exchangeRates: false,
      };
    case FX_HISTORY_UPDATE:
      return {
        ...state,
        exchangeRates: state.exchangeRates.map((item) => {
          if (item.id === action.payload.itemID) {
            return action.payload.data.data;
          } else {
            return item;
          }
        }),
        exchangeRatesError: false,
      };
    case FX_HISTORY_UPDATE_ERROR:
      return {
        ...state,
        exchangeRatesError: action.payload,
        exchangeRates: false,
      };
    case DELETE_FX_HISTORY:
      return {
        ...state,
        exchangeRates: state.exchangeRates.filter(
          (item) => item.settlementDate !== action.payload
        ),
        exchangeRatesError: false,
      };
    case DELETE_FX_HISTORY_ERROR:
      return {
        ...state,
        exchangeRatesError: action.payload,
        exchangeRates: false,
      };
    case GET_ALL_CURRENCY_CODES:
      return {
        ...state,
        allCurrencyCodes: action.payload,
        allCurrencyCodesError: false,
      };
    case GET_ALL_CURRENCY_CODES_ERROR:
      return {
        ...state,
        allCurrencyCodesError: action.payload,
        allCurrencyCodes: false,
      };
    case ADD_RATES:
      return {
        ...state,
        exchangeRates: [...state.exchangeRates, action.payload],
        exchangeRatesError: false,
      };
    case ADD_RATES_ERROR:
      return {
        ...state,
        addRatesError: action.payload,
        addRates: false,
        alertError: true,
      };
    case GETTING_CURRENT_USER_AFFILIATES:
      return { ...state, gettingCurrentUserAffiliates: action.payload.data };
    case GET_OPERATORS:
      return { ...state, operators: action.payload };
    case GET_LOGS:
      return { ...state, fileLogs: action.payload, fileLogsError: "" };
    case GET_LOGS_ERROR:
      return { ...state, fileLogs: [], fileLogsError: action.payload };
    case GET_CHANNEL_LOGS:
      return { ...state, channelLogs: action.payload };
    case GET_CURRENCY:
      return { ...state, currencies: action.payload };
    case DECODE_TOKEN:
      return { ...state, decodeToken: action.payload };
    case GET_USERS:
      return { ...state, users: action.payload.data };
    case GET_USERS_ERROR:
      return { ...state, usersError: action.payload };
    case ADD_AFFILIATES_TO_USERS:
      return { ...state, addAffiliatesToUsers: action.payload.data };
    case ADD_AFFILIATES_TO_USERS_ERROR:
      return { ...state, addAffiliatesToUsersError: action.payload };
    case GET_PENDING_AUTHORIZATIONS:
      return { ...state, pendingAuthorizations: action.payload.data };
    case GET_LOGGED_IN_USER:
      return { ...state, userDetail: action.payload };
    case GET_LOGGED_IN_USER_ERROR:
      return { ...state, userDetailError: action.payload };
    case GET_USER_AFFILIATES:
      return { ...state, userAffiliates: action.payload.data };
    case GET_LOGGED_IN_USER_AFFILIATES:
      // loggedInUserAffiliates: this is where the logged in User affiliates are stored to seperate it from the normal userAffiliates
      return { ...state, loggedInUserAffiliates: action.payload.data };
    case GET_USER_AFFILIATES_ERROR:
      return { ...state, userAffiliatesError: action.payload };
    case ADD_AFFILIATE_TO_USER:
      // Checks if the user is the logged in user before adding an affilate to him/her
      if (
        action.payload.code.login === localStorage.getItem("loggedInUserEmail")
      ) {
        return {
          ...state,
          code: action.payload.data.data.code,
          loggedInUserAffiliates: [
            ...state.loggedInUserAffiliates,
            action.payload.data.data,
          ],
        };
      }
      return {
        ...state,
        code: action.payload.data.data.code,
        userAffiliates: [...state.userAffiliates, action.payload.data.data],
      };
    case ADD_AFFILIATE_TO_USER_ERROR:
      return { ...state, affiliateAddedToUserError: action.payload };
    case REMOVE_AFFILIATE_FROM_USER:
      // Checks if the user is the logged in user before removing an affilate to him/her
      let data;
      if (action.payload.login === localStorage.getItem("loggedInUserEmail")) {
        data = state.loggedInUserAffiliates.filter(
          (item) => item.code !== action.payload.affiliateCode
        );
        return { ...state, loggedInUserAffiliates: [...data] };
      }
      data = state.userAffiliates.filter(
        (item) => item.code !== action.payload.affiliateCode
      );
      return { ...state, userAffiliates: [...data] };
    case GET_SCHEME_STANDINGS:
      return { ...state, schemeStandings: action.payload };
    case GET_SCHEME_STANDINGS_ERROR:
      return { ...state, schemeStandingsError: action.payload };
    case REMOVE_AFFILIATE_FROM_USER_ERROR:
      return { ...state, affiliateRemovedFromUserError: action.payload };
    case APPROVE_AUTHORIZATION:
      state.pendingAuthorizations.payload.forEach((item) =>
        console.log(item.id)
      );
      const remainingAuthorizations =
        state.pendingAuthorizations.payload.filter(
          (item) => item.id !== action.payload.data.redisId
        );
      state.pendingAuthorizations.payload = remainingAuthorizations;
      return { ...state, authorizationApproved: action.payload };
    case APPROVE_AUTHORIZATION_ERROR:
      return { ...state, authorizationApprovedError: action.payload };
    case GET_ADMIN_REPORTS:
      return { ...state, adminReports: action.payload };
    case GET_ADMIN_REPORTS_ERROR:
      return { ...state, adminReportsError: action.payload };
    case GET_POST_TRANSACTION_REPORT:
      return { ...state, postTransactionReport: action.payload };
    case GET_POST_TRANSACTION_REPORT_ERROR:
      return { ...state, postTransactionReportError: action.payload };
      case GET_SETTLEMENT_USERS:
      return { ...state, settlementUsers: action.payload };
      case GET_SETTLEMENT_USERS_ERROR:
      return { ...state, settlementUsersError: action.payload };
    default:
      return state;
  }
}
