import axios from "axios";

import {
  BASE_URL,
  UPLOAD_URL,
  GET_OPERATORS,
  GET_LOGS,
  GET_LOGS_ERROR,
  GET_CHANNEL_LOGS,
  GET_CURRENCY,
  GET_PENDING_AUTHORIZATIONS,
  APPROVE_AUTHORIZATION,
  APPROVE_AUTHORIZATION_ERROR,
  GET_USERS,
  GET_USERS_ERROR,
  ADD_AFFILIATES_TO_USERS,
  ADD_AFFILIATES_TO_USERS_ERROR,
  REMOVE_AFFILIATE_FROM_USER,
  REMOVE_AFFILIATE_FROM_USER_ERROR,
  GET_LOGGED_IN_USER,
  GET_LOGGED_IN_USER_ERROR,
  ADD_AFFILIATE_TO_USER,
  ADD_AFFILIATE_TO_USER_ERROR,
  GET_SCHEME_STANDINGS,
  GET_SCHEME_STANDINGS_ERROR,
  GET_USER_AFFILIATES,
  GET_LOGGED_IN_USER_AFFILIATES,
  FX_HISTORY,
  FX_HISTORY_ERROR,
  FX_HISTORY_UPDATE,
  FX_HISTORY_UPDATE_ERROR,
  MANUAL_RESOLUTION,
  FILE_UPLOAD,
  FILE_UPLOAD_ERROR,
  REFRESH,
  DELETE_CHANNEL_LOG_ITEM,
  DELETE_CHANNEL_LOG_ITEM_ERROR,
  UPLOADING,
  EXT_USER_MGT_URL,
  USER_MGT_URL_FRONTEND_URL,
  USER_MGT_URL_FRONTEND_URL_ERROR,
  OPEN_MODAL,
  CLOSE_MODAL,
  GET_ALL_CURRENCY_CODES_ERROR,
  GET_ALL_CURRENCY_CODES,
  ADD_RATES,
  ADD_RATES_ERROR,
  OPEN_ALERT,
  CLOSE_ALERT,
  GET_AUTHORIZATION_TOKEN,
  GET_AUTHORIZATION_TOKEN_ERROR,
  DELETE_FX_HISTORY,
  DELETE_FX_HISTORY_ERROR,
  DOWNLOADING_STATUS,
  GET_ADMIN_REPORTS,
  GET_ADMIN_REPORTS_ERROR,
  GET_POST_TRANSACTION_REPORT,
  GET_POST_TRANSACTION_REPORT_ERROR,
  GET_SETTLEMENT_USERS,
  GET_SETTLEMENT_USERS_PAGINATED,
  DELETE_SETTLEMENT_USER,
  GET_SETTLEMENT_USERS_ERROR,
} from "./types";

const USER_MGT_BACKEND_URL = localStorage.getItem("USER_MGT_URL_BACKEND");

export const openAlert = () => (dispatch) => {
  dispatch({
    type: OPEN_ALERT,
    payload: { data: true },
  });
};

export const closeAlert = () => (dispatch) => {
  dispatch({
    type: CLOSE_ALERT,
    payload: { data: false },
  });
};

export const openModal = () => (dispatch) => {
  dispatch({
    type: OPEN_MODAL,
    payload: { data: true },
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_MODAL,
    payload: { data: false },
  });
};

export const handleExceptionMannually = () => (dispatch) => {
  dispatch({
    type: MANUAL_RESOLUTION,
    payload: { data: false },
  });
};

export const handleException = (selectedItem) => (dispatch) => {
  dispatch({
    type: MANUAL_RESOLUTION,
    payload: {
      data: true,
      selectedItem,
    },
  });
};

export const getUserMgtUrl = () => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}/users/auth-frontend-url`);
    localStorage.setItem("USER_MGT_URL", response.data);
    dispatch({
      type: USER_MGT_URL_FRONTEND_URL,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: USER_MGT_URL_FRONTEND_URL_ERROR,
      payload: error.response,
    });
  }
};

export const getUserMgtUrlBackEnd = () => async (dispatch) => {
  const response = await axios.get(`${BASE_URL}/users/auth-backend-url`);
  localStorage.setItem("USER_MGT_URL_BACKEND", response.data);
  dispatch({
    type: EXT_USER_MGT_URL,
    payload: response,
  });
};

export const refreshUpload = () => (dispatch) => {
  dispatch({
    type: REFRESH,
    payload: false,
  });
};

export const deleteChangeLogItem = (file) => (dispath) => {
  axios
    .delete(`${BASE_URL}/admin/logs/delete?filename=${file.fileName}`)
    .then((response) => {
      dispath({
        type: DELETE_CHANNEL_LOG_ITEM,
        payload: { data: file.id },
      });
    })
    .catch((error) => {
      dispath({
        type: DELETE_CHANNEL_LOG_ITEM_ERROR,
        payload: error,
      });
    });
};

export const deleteFxHistory = (date) => (dispath) => {
  axios
    .delete(`${BASE_URL}/exchange-rates?settlementDate=${date}`)
    .then((response) => {
      dispath({
        type: DELETE_FX_HISTORY,
        payload: date,
      });
    })
    .catch((error) => {
      dispath({
        type: DELETE_FX_HISTORY_ERROR,
        payload: error,
      });
    });
};

export const handleFileUpload = (file) => (dispatch) => {
  axios
    .post(`${UPLOAD_URL}/upload`, file, {
      onUploadProgress: (progressEvent) => {
        dispatch({
          type: UPLOADING,
          payload: Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          ),
        });
      },
    })
    .then((response) => {
      dispatch({
        type: FILE_UPLOAD,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FILE_UPLOAD_ERROR,
        payload: err,
      });
    });
};

export const fxhistory = (queryParam) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/exchange-rates${queryParam}`,
  })
    .then((response) => {
      dispatch({
        type: FX_HISTORY,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FX_HISTORY_ERROR,
        payload: err,
      });
    });
};

export const updateFxHistory =
  (item, sellRate, buyRate, midRate) => (dispatch) => {
    const values = {
      id: item.id,
      settlementDate: item.settlementDate,
      buyRate,
      sellRate,
      midRate,
    };
    axios
      .put(
        `${BASE_URL}/exchange-rates?date=${item.settlementDate}&fromCurrencyCode=${item.fromCurrencyCode}&toCurrencyCode=${item.toCurrencyCode}`,
        values
      )
      .then((response) => {
        dispatch({
          type: FX_HISTORY_UPDATE,
          payload: { data: response, itemID: item.id },
        });
      })
      .catch((err) => {
        dispatch({
          type: FX_HISTORY_UPDATE_ERROR,
          payload: err,
        });
      });
  };

export const deleteExchangeRate = (date) => (dispatch) => {
  axios
    .delete(`${BASE_URL}/exchange-rates?settlementDate=${date}`)
    .then((response) => {
      console.log(response);
      response.message = "Rates deleted!";
      dispatch({
        type: DELETE_FX_HISTORY,
        payload: response,
      });
    })
    .catch((err) => {
      err.message = "Rates could not be deleted!";
      dispatch({
        type: DELETE_FX_HISTORY_ERROR,
        payload: err,
      });
    });
};
export const addFxHistoryRates = (newRate) => (dispatch) => {
  axios
    .post(`${BASE_URL}/exchange-rates?date=${newRate.settlementDate}`, newRate)
    .then((response) => {
      dispatch({
        type: ADD_RATES,
        payload: newRate,
      });
    })
    .catch((err) => {
      dispatch({
        type: ADD_RATES_ERROR,
        payload: err,
      });
    });
};

export const getAllCurrencyCodes = () => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/exchange-rates/currencies`,
  })
    .then((response) => {
      dispatch({
        type: GET_ALL_CURRENCY_CODES,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ALL_CURRENCY_CODES_ERROR,
        payload: err,
      });
    });
};

export const fetchSchemeStanding = (scheme) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/schemes?scheme=${scheme}`,
  })
    .then((response) => {
      dispatch({
        type: GET_SCHEME_STANDINGS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_SCHEME_STANDINGS_ERROR,
        payload: err,
      });
    });
};

export const addAffilaiteToUser = (values) => (dispatch) => {
  axios
    .post(`${BASE_URL}/users/add-affiliate`, values)
    .then((response) => {
      dispatch({
        type: ADD_AFFILIATE_TO_USER,
        payload: { data: response, code: values },
      });
    })
    .catch((err) => {
      dispatch({
        type: ADD_AFFILIATE_TO_USER_ERROR,
        payload: err,
      });
    });
};

export const removeAffilaiteFromUser = (values) => (dispatch) => {
  localStorage.removeItem("selectedAffilliate");
  axios
    .post(`${BASE_URL}/users/remove-affiliate`, values)
    .then((response) => {
      dispatch({
        type: REMOVE_AFFILIATE_FROM_USER,
        payload: values,
      });
    })
    .catch((err) => {
      dispatch({
        type: REMOVE_AFFILIATE_FROM_USER_ERROR,
        payload: err,
      });
    });
};

export const addAffiliatesToUsers = (values, email) => (dispatch) => {
  axios
    .post(`${BASE_URL}/users/${email}/add-affiliates`, values)
    .then((response) => {
      dispatch({
        type: ADD_AFFILIATES_TO_USERS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: ADD_AFFILIATES_TO_USERS_ERROR,
        payload: err,
      });
    });
};

export const fetchUserAffiliates = (email) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/users/affiliates?login=${email}`,
  })
    .then((response) => {
      dispatch({
        type: GET_USER_AFFILIATES,
        payload: { data: response.data },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_USER_AFFILIATES,
        // Set array to empty instead of false
        payload: { data: [] },
        // payload : { data: [ false, false, false ] },
      });
    });
};

// Function to fetch Logged In user Affiliates
// I created it so as to seperate the type of ations been executed `${type    : GET_LOGGED_IN_USER_AFFILIATES}`
export const fetchLoggedInUserAffiliates = (email) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/users/affiliates?login=${email}`,
  })
    .then((response) => {
      dispatch({
        type: GET_LOGGED_IN_USER_AFFILIATES,
        payload: { data: response.data },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_LOGGED_IN_USER_AFFILIATES,
        // Set array to empty instead of false
        payload: { data: [] },
        // payload : { data: [ false, false, false ] },
      });
    });
};

export const fetchOperators = () => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/etc/operators`,
  }).then((response) => {
    dispatch({
      type: GET_OPERATORS,
      payload: response,
    });
  });
};

export const fetchUsers = (type='') => (dispatch) => {
  let path = type === '' ? '/userapps' :  '/userapps' + '?username=' + type;

  axios
    .get(`${USER_MGT_BACKEND_URL}${path}`, {
      headers: { Module: "SETTLEMENT" },
    })
    .then((response) => {
      dispatch({
        type: GET_USERS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_USERS_ERROR,
        payload: err,
      });
    });
};

export const fetchUsersPaginated = (pageNumber,search='') => (dispatch) => {
  let type = search === '' ? `/userapps?page=${pageNumber}&size=20`:  `/userapps?page=${pageNumber}&size=20` + '&username=' + search;

  axios
    .get(`${USER_MGT_BACKEND_URL}${type}`, {
      headers: { Module: "SETTLEMENT" },
    })
    .then((response) => {
      dispatch({
        type: GET_USERS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_USERS_ERROR,
        payload: err,
      });
    });
};

export const fetchCurrencies = () => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/currencies`,
  }).then((response) => {
    dispatch({
      type: GET_CURRENCY,
      payload: response,
    });
  });
};

export const fetchLogs = () => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/admin/logs/processing`,
  }).then((response) => {
    dispatch({
      type: GET_LOGS,
      payload: response,
    });
  });
};

export const fetchLogsPaginated =
  (
    page,
    startDate,
    endDate,
    failed,
    fileName,
    logType,
    affiliateCode,
    settlementDate
  ) =>
  async (dispatch) => {
    try {
      const { data: payload } = await axios({
        method: "GET",
        url: `${BASE_URL}/admin/logs/processing?page=${page}&startDate=${startDate}&endDate=${endDate}&failed=${failed}${
          fileName ? `&filename=${fileName}` : ""
        }${logType ? `&logType=${logType}` : ""}${
          settlementDate ? `&settlementDate=${settlementDate}` : ""
        }${affiliateCode ? `&affiliateCode=${affiliateCode}` : ""}`,
      });
      dispatch({
        type: GET_LOGS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: GET_LOGS_ERROR,
        payload: error.message,
      });
    }
  };

export const downloadProcessingLogs =
  (
    page,
    startDate,
    endDate,
    failed,
    fileName,
    logType,
    affiliateCode,
    settlementDate
  ) =>
  async () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/admin/logs/processing?csv&page=${page}&startDate=${startDate}&endDate=${endDate}&failed=${failed}${
        fileName ? `&filename=${fileName}` : ""
      }${logType ? `&logType=${logType}` : ""}${
        settlementDate ? `&settlementDate=${settlementDate}` : ""
      }${affiliateCode ? `&affiliateCode=${affiliateCode}` : ""}`,
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `processing_logs.csv`);
      document.body.appendChild(link);
      link.click();
      return;
    });
  };

export const fetchChannelLogs = () => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/admin/logs/channels`,
  }).then((response) => {
    dispatch({
      type: GET_CHANNEL_LOGS,
      payload: response.data,
    });
  });
};

export const fetchChannelLogsPaginated = (pageNumber) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/admin/logs/channels?page=${pageNumber}`,
  }).then((response) => {
    dispatch({
      type: GET_CHANNEL_LOGS,
      payload: response.data,
    });
  });
};

export const getAuthorizationToken =
  (username, password) => async (dispatch) => {
    const URL = `${USER_MGT_BACKEND_URL}`; // grab the user managment backend auth service endpoint it return new token
    const Body = { username, password }; // same as username: username, password: password
    try {
      const response = await axios.post(URL, Body);
      dispatch({
        type: GET_AUTHORIZATION_TOKEN,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_AUTHORIZATION_TOKEN_ERROR,
        payload: error.response,
      });
    }
  };

export const getLoggedInUser = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/users`,
    })
      .then((response) => {
        localStorage.setItem("loginUser", response.data.login);
        dispatch({
          type: GET_LOGGED_IN_USER,
          payload: response.data,
        });
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: GET_LOGGED_IN_USER_ERROR,
          payload: err,
        });
      });
  });
};

export const fetchPendingAuthorizations = () => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/todos`,
  }).then((response) => {
    dispatch({
      type: GET_PENDING_AUTHORIZATIONS,
      payload: response,
    });
  });
};

export const approveAuthorization = (id, name) => (dispatch) => {
  axios
    .put(`${BASE_URL}/${name.toLowerCase()}?redisKey=${id}`, {})
    .then((response) => {
      response.data.redisId = id;
      dispatch({
        type: APPROVE_AUTHORIZATION,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: APPROVE_AUTHORIZATION_ERROR,
        payload: err.response.data,
      });
    });
};
export const fetchAdminReports =
  (page, startDate, endDate, affiliateCodes, tag, schemes) =>
  async (dispatch) => {
    try {
      const { data: payload } = await axios({
        method: "GET",
        url: `${BASE_URL}/admin/filter-posting-records?affiliateCodes=${affiliateCodes}&tag=${tag}&schemes=${schemes}&startDate=${startDate}&endDate=${endDate}&page=${page}`,
      });
      dispatch({
        type: GET_ADMIN_REPORTS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: GET_ADMIN_REPORTS_ERROR,
        payload: error.message,
      });
    }
  };
export const downloadAdminReport =
  (startDate, endDate, affiliateCodes, tag, schemes) => async (dispatch) => {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${BASE_URL}/admin/filter-posting-records?affiliateCodes=${affiliateCodes}&tag=${tag}&schemes=${schemes}&startDate=${startDate}&endDate=${endDate}`,
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Admin_Report.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch({
        type: GET_ADMIN_REPORTS_ERROR,
        payload: error.message,
      });
    }
  };

export const downloadPostTransactionReport =
  (startDate, endDate, affiliateCodes, schemes) => async (dispatch) => {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${BASE_URL}/admin/filter-posting-transactions?affiliateCodes=${affiliateCodes}&schemes=${schemes}&startDate=${startDate}&endDate=${endDate}&csv`,
        headers: {
          "Content-Type": "application/octet-stream",
        },
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Posting_Transactions.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch({
        type: GET_ADMIN_REPORTS_ERROR,
        payload: error.message,
      });
    }
  };

export const fetchPostTransactionReport =
  (startDate, endDate, affiliateCodes, schemes) => async (dispatch) => {
    try {
      const { data: payload } = await axios({
        method: "GET",
        url: `${BASE_URL}/admin/filter-posting-transactions?affiliateCodes=${affiliateCodes}&schemes=${schemes}&startDate=${startDate}&endDate=${endDate}`,
      });
      dispatch({
        type: GET_POST_TRANSACTION_REPORT,
        payload,
      });
    } catch (error) {
      dispatch({
        type: GET_POST_TRANSACTION_REPORT_ERROR,
        payload: error.message,
      });
    }
  };

export const fetchSettlementUsers = (size) => (dispatch) => {
  return new Promise((resolve) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/users/all?size=${size}`,
    })
      .then((response) => {
        dispatch({
          type: GET_SETTLEMENT_USERS,
          payload: response.data,
        });
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: GET_SETTLEMENT_USERS_ERROR,
          payload: err,
        });
      });
  });
};

export const fetchSettlementUsersPaginated =
  (pageNumber, size) => async (dispatch) => {
    return new Promise((resolve) => {
      axios({
        method: "GET",
        url: `${BASE_URL}/users/all?size=${size}&page=${pageNumber}`,
      })
        .then((response) => {
          dispatch({
            type: GET_SETTLEMENT_USERS,
            payload: response.data,
          });
          resolve(response.data);
        })
        .catch((err) => {
          dispatch({
            type: GET_SETTLEMENT_USERS_ERROR,
            payload: err,
          });
        });
    });
  };

export const deleteSettlementUsers = (id) => async () => {
  axios.delete(`${BASE_URL}/users/${id}`);
};
